.rectangle-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;
    padding: 15px;
}

.rectangle {
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    padding: 2rem;
    border-radius: 24px;
    max-width: 820px;
    width: 100%;
    height: 150px;
    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

    .rectangle .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 820px;
        width: 100%;
    }

    .rectangle:hover {
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    }

h6.rectangle-body {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0;
}

.rectangle-logo-container {
    width: 110px;
}

img.rectangle-logo {
    width: 100%;
}

.timer {
    font-size: 1.6rem;
    color: #fff;
    width: fit-content;
    font-weight: 700;
    border-radius: 24px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 15px;
}

.timer-part {
    background-color: #061d45;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    gap: 0.2rem;
    padding: 20px;
}

    .timer-part.music-time {
        background-color: #52c41a;
    }

.timer-colon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #061d45;
    font-size: 2rem;
}

.slider-container {
    margin-bottom: 15px;
}

.slider {
    background-color: #061d45;
    height: 320px;
    padding: 4rem;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

    .slider h4,
    .slider h3,
    .slider p {
        color: #fff;
    }

    .slider h4 {
        font-size: 3rem;
    }

    .slider h3 {
        font-size: 2.2rem;
    }

    .slider p {
        font-size: 1.2rem;
    }

img.slider-icon {
    width: 210px;
}

.slider-info {
    white-space: nowrap;
}

.ant-carousel .slick-dots {
    z-index: 0 !important;
}

.timer-part-button-btn {
    border-radius: 6px;
    background-color: #061d45;
    display: block;
    height: 44px;
    min-width: 140px;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    line-height: 0;
    box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

    .timer-part-button-btn.register-open {
        background-color: #52c41a;
        border: 1px solid #40851f;
    }

button.timer-part-button-btn.register-open.disabled {
    cursor: default;
    box-shadow: none;
    border: none;
}

.timer-part-button-btn.register-open:hover,
.timer-part-button-btn.register-open:focus {
    color: #fff;
    background-color: #60d526;
    box-shadow: rgb(0 0 0 / 40%) 0px 5px 7px, rgb(0 0 0 / 30%) 0px 10px 16px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
}

.timer-part-button-btn:hover,
.timer-part-button-btn:focus {
    color: #fff;
    background-color: #0d2f68;
    box-shadow: rgb(0 0 0 / 40%) 0px 5px 7px, rgb(0 0 0 / 30%) 0px 10px 16px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
}

@media only screen and (min-width : 1224px) {
    .slider {
        padding-right: 25%;
        padding-left: 25%;
    }
}

@media only screen and (max-width : 834px) {
}

@media only screen and (min-width : 768px) and (max-width : 834px) and (orientation : landscape) {
}

@media only screen and (min-width : 768px) and (max-width : 834px) and (orientation : portrait) {
}

@media only screen and (max-width : 667px) {
    main.ant-layout-content.site-layout-content {
        margin-top: -25px;
    }

    .rectangle-container {
        row-gap: 0.8rem;
    }

    .slider .icon {
        position: absolute;
        z-index: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 221px;
        opacity: 0.2;
    }

    img.slider-icon {
        width: 132px;
    }

    .slider-info {
        margin-top: 12px;
        text-align: center;
    }

    .rectangle {
        padding-top: 12px;
        padding-bottom: 12px;
        height: 116px;
        border-radius: 12px;
    }

    .slider {
        flex-direction: column;
        justify-content: end;
    }

    .slider {
        height: 190px;
        padding: 2rem;
        justify-content: start;
    }

        .slider h4,
        .slider h3,
        .slider p {
            line-height: 28px;
        }

        .slider h4 {
            font-size: 2.1rem;
        }

        .slider h3 {
            font-size: 1.2rem;
        }

        .slider p {
            font-size: 1rem;
        }

    .slider-container {
        margin-bottom: 0;
    }

    .rectangle-logo-container {
        width: 63px;
    }

    h6.rectangle-body {
        font-size: 1.2rem;
        font-weight: inherit;
    }

    .timer-part {
        background-color: #061d45;
        box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
        height: 4rem;
    }
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : landscape) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : portrait) {
}

@media only screen and (max-width : 667px) and (orientation : portrait) {
}
