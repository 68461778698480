.site-layout-content {
    min-height: 280px;
    background: #fff;
    padding: 0;
    padding-bottom: 20px;
}

footer {
    background-color: #061d45;
    text-align: center;
}

.submenu-body a {
    display: block;
}

.burger-menu,
.bm-burger-button {
    display: none;
}

.burger-menu {
    /*box-shadow: 8px 8px 12px rgb(0 0 0 / 45%);*/
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 36px;
    height: 30px;
    top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: rgb(70 70 70 / 85%);
    border-radius: 8px;
    height: 16% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
}

/* General sidebar styles */
.bm-menu {
    /*background: #061d45;*/
    background: #05132a;
    padding: 1.5em 1em 0;
    /*font-size: 1.15em;*/
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #061d45;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    padding-right: 0;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    right: 0;
}

@media only screen and (min-width : 1224px) {
}

@media only screen and (max-width : 834px) {
}

@media only screen and (min-width : 768px) and (max-width : 834px) and (orientation : landscape) {
}

@media only screen and (min-width : 768px) and (max-width : 834px) and (orientation : portrait) {
}

@media only screen and (max-width : 716px) {
    .top-menu,
    .ant-menu-overflow,
    .menu-logo {
        display: none !important;
    }

    .burger-menu,
    .bm-burger-button {
        display: block;
    }

    .ant-layout-header {
        height: 64px;
        padding: 0 15px;
    }
    .ant-layout-header {
        padding: 0 25px !important;
    }
}

@media only screen and (max-width : 667px) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : landscape) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : portrait) {
}
