.ad-video-container {
    padding: 15px;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.answer-input {
    text-align: center;
    padding: 15px;
}

    .answer-input .answer-input-text {
        border-radius: 6px;
        padding: 6px 14px;
        font-size: 1.4rem;
        border: 1px solid rgb(0 0 0 / 45%);
        box-shadow: 2px 2px 3px rgb(0 0 0 / 25%) inset;
        color: #000;
        font-weight: 500;
        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    .answer-input .answer-input-btn {
        background-color: #061d45;
        box-shadow: rgb(0 0 0 / 40%) 0px 2px 4px, rgb(0 0 0 / 30%) 0px 7px 13px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
        height: 49px;
        border-radius: 6px;
        color: #fff;
        font-size: 1rem;
        padding: 0 18px;
        cursor: pointer;
        transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    }

        .answer-input .answer-input-btn:hover,
        .answer-input .answer-input-btn:focus {
            color: #fff;
            background-color: #0d2f68;
            box-shadow: rgb(0 0 0 / 40%) 0px 5px 7px, rgb(0 0 0 / 30%) 0px 10px 16px -3px, rgb(0 0 0 / 20%) 0px -3px 0px inset;
        }

.video {
    height: 490px;
    margin-right: auto;
    margin-left: auto;
}

@media only screen and (max-width : 900px) {
    .video {
        margin-right: 15px;
        margin-left: 15px;
    }

        .video video {
            height: auto !important;
        }
}

@media only screen and (max-width : 667px) {
        
}
