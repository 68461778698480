.login-container {
    margin: 0;
    padding: 0;
    position: relative;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    z-index: 10;
}

.login-background {
    background-color: rgba(6,29,69,1);
    padding: 8px 11px;
    text-align: center;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    border: 1px solid rgb(255 255 255 / 11%);
}

.login {
    background-color: #fff;
    border: 1px solid rgb(211, 211, 211);
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 0 0 3px 3px;
}

.login-header {
    color: #fff;
    margin-top: 15px;
}

.bg-image {
    background-image: url(https://expand.co.za/wp-content/uploads/2022/03/Home-image-4-1920x1281.jpg);
    position: absolute;
    top: -50%;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 5;
}

@media only screen and (min-width : 1224px) {
}

@media only screen and (max-width : 834px) {
}

@media only screen and (min-width : 768px) and (max-width : 834px) and (orientation : landscape) {
}

@media only screen and (min-width : 768px) and (max-width : 834px) and (orientation : portrait) {
}

@media only screen and (max-width : 716px) {
}

@media only screen and (max-width : 667px) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : landscape) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : portrait) {
}