.results-container,
.airtime-container,
.answer-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin: auto;
    max-width: 720px;
}

.results-rectangle,
.results-list {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: left;
    max-width: 720px;
    border-radius: 24px;
    padding: 2rem;
    background-color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid #d9d9d9;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.results-rectangle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #d9d9d9;
    transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -moz-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -ms-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: 0.3s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.airtime-card {
    text-align: left;
    max-width: 720px;
    border-radius: 24px;
    padding: 1rem;
    background-color: #fff;
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid #d9d9d9;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.results-list {
    text-align: left;
    border: 1px solid rgb(0 0 0 / 32%);
}

.results-rectangle.you {
}

.results-list:hover,
.results-rectangle:hover {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
}

.results-list-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
}

    .results-list-item:hover {
        background-color: rgb(0 0 0 / 12%);
    }

.airtime-container {
    padding-bottom: 3rem;
}

.info {
    font-size: 1.4rem;
    font-weight: 600;
}

.position {
    color: rgb(112, 172, 22);
    font-weight: 500;
}

.results-list .position {
    font-size: 1.4rem;
    min-width: 3rem;
    display: inline-block;
}

.name {
    font-weight: 500;
}

.amount {
    color: rgb(233, 92, 40);
    font-weight: 500;
    font-size: 2.4rem;
}

.results-list .amount {
    font-size: 1.4rem;
}

.back-top {
    font-size: 28px;
    background-color: #061d45;
    border-radius: 4px;
    text-align: center;
    height: 45px;
    width: 45px;
    box-shadow: 3px 3px 6px rgb(0 0 0 / 45%);
    border-radius: 78px;
}

.ant-back-top {
    color: #fff !important;
}

:root {
    --color-first: #65587f;
    --color-second: #f18867;
    --color-third: #e85f99;
    --color-forth: #50bda1;
    --block-width: 300px;
    --block-height: 180px;
    --border-width: .625rem;
    --border-radius-outer: 8px;
    --border-radius-inner: calc(var(--border-radius-outer) / 2);
    --font-plain: "IBM Plex Sans", sans-serif;
    --font-special: "Fredoka One", sans-serif;
    box-sizing: border-box;
    line-height: 1.4;
}

*, *:before, *:after {
    box-sizing: inherit;
}

.rainbow {
    width: 100%;
    height: 100%;
    -webkit-animation: o-rotate-360 linear 12s infinite;
    animation: o-rotate-360 linear 12s infinite;
}

    .rainbow span {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        transform: scaleY(4) translate(-50%, 26%);
    }

        .rainbow span:after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 100%;
        }

        .rainbow span:first-child {
            background: var(--color-first);
            top: -320%;
        }

            .rainbow span:first-child:after {
                background: var(--color-second);
            }

        .rainbow span:last-child {
            background: var(--color-third);
        }

            .rainbow span:last-child:after {
                background: var(--color-forth);
            }

.c-subscribe-box {
    height: var(--block-height);
    overflow: hidden;
    position: relative;
}

.c-subscribe-box__wrapper {
    width: calc(100% - var(--border-width));
    height: calc(100% - var(--border-width));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: flex;
    border-radius: 22px;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
}

@-webkit-keyframes o-rotate-360 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes o-rotate-360 {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

nav.results-nav {
    padding: 15px;
}

.results-nav .results-nav-link a {
    padding: 12px 6px;
    color: #000;
    display: block;
}

.results-content {
    text-align: center;
}

.results-nav {
    position: fixed;
    width: 154px;
    padding: 15px;
    top: 167px;
}

.sider-icon {
    font-size: 1.2rem;
}

.sider-label {
    display: inline-block;
    margin-left: 8px;
    vertical-align: middle;
}

.ant-card-head-title {
    overflow: visible !important;
    white-space: pre-wrap !important;
    text-overflow: initial !important;
}

@media only screen and (min-width : 1224px) {
}

@media only screen and (max-width : 991px) {
    .results-nav {
        width: 324px;
        padding: 15px;
        top: auto;
        bottom: 10px;
        margin: auto;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        background-color: #061d45;
        border-radius: 24px;
        box-shadow: 3px 3px 6px rgb(0 0 0 / 45%);
    }

        .results-nav .results-nav-link a {
            color: #fff;
            display: contents;
        }

    .amount {
        font-size: 1.6rem;
    }

    .results-rectangle, .results-list {
        border-radius: 12px;
    }

    .c-subscribe-box__wrapper {
        border-radius: 8px;
    }
}

@media only screen and (max-width : 667px) {
}

@media only screen and (max-width : 834px) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : landscape) {
}

@media only screen and (min-width : 375px) and (max-width : 667px) and (orientation : portrait) {
}
